import type React from 'react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import { useTranslation } from 'react-i18next'

interface ErrorBoundarWrapperProps {
  children: React.ReactNode
}

function ErrorBoundaryWrapper({
  children,
}: ErrorBoundarWrapperProps): React.ReactNode {
  const [paths, setPaths] = useState<Record<string, boolean>>({})
  const location = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    if (paths[location.pathname] === undefined) {
      setPaths((prevState) => ({
        ...prevState,
        [location.pathname]: false,
      }))
    }
  }, [location.pathname])

  const setError = (): void => {
    setPaths((prevState) => ({
      ...prevState,
      [location.pathname]: true,
    }))
  }

  return (
    <ErrorBoundary
      hasError={paths[location.pathname] ?? false}
      setError={setError}
      errorDescription={t('ERRORS.ERROR_BOUNDARY')}
    >
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryWrapper
