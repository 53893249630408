import httpSecure from './AxiosSecureAbstractInstance'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { getResponseType } from '../enums/GetResponseType'
import {
  AUTH_COOKIE_NAME,
  AUTH_FINGERPRINT,
} from '../../modules/auth/cookies/authCookiesKeys'
import {
  type IPatchProps,
  type IApiSecureArielAxiosHttpsClient,
  type IDeleteProps,
  type IGetProps,
  type IPostProps,
  type IPutProps,
} from './interfaces/IApiSecureArielAxiosHttpClient'
import { PUBLIC_ROUTES } from '../../../Routes'
import { type AxiosRequestConfig } from 'axios'

const cookies = new Cookies()

const useApiSecureAxiosHttpClient = (): IApiSecureArielAxiosHttpsClient => {
  const navigate = useNavigate()

  const redirectLogout = (error: any): void => {
    console.error('Invalid auth --->', error.response)
    if (error.response.status === 403 || error.response.status === 401) {
      navigate(
        `/${PUBLIC_ROUTES.AUTH.ROOT}/${PUBLIC_ROUTES.AUTH.CHILDREN.LOGOUT}`,
      )
    } else if (error.response.status === 409) {
      navigate(
        `/${PUBLIC_ROUTES.AUTH.ROOT}/${PUBLIC_ROUTES.AUTH.CHILDREN.LOGOUT}/${PUBLIC_ROUTES.AUTH.CHILDREN.NEW_ACCESS_DETECTED}`,
      )
    }
  }

  const buildConfig = (
    customConfig: AxiosRequestConfig | undefined,
  ): AxiosRequestConfig => {
    let config: any = {}

    if (cookies.get(AUTH_COOKIE_NAME)) {
      config.headers = {
        Authorization: `Bearer ${cookies.get(AUTH_COOKIE_NAME)}`,
      }
    }
    if (cookies.get(AUTH_FINGERPRINT)) {
      config.headers = {
        ...config.headers,
        fingerprint: cookies.get(AUTH_FINGERPRINT),
      }
    }
    if (customConfig) {
      config = {
        ...customConfig,
        headers: {
          ...config.headers,
          ...(customConfig.headers ?? {}),
        },
      }
    }
    return config
  }

  const get = async ({
    path,
    customConfig,
    responseType = getResponseType.DATA,
  }: IGetProps): Promise<any> => {
    return await new Promise((resolve, reject) => {
      const config = buildConfig(customConfig)

      httpSecure
        .get(path, config)
        .then((response) => {
          if (responseType === getResponseType.HEADERS_AND_DATA) {
            resolve({
              data: response.data,
              headers: response.headers,
            })
          } else {
            resolve(response.data)
          }
        })
        .catch((error) => {
          redirectLogout(error)
          reject(error)
        })
    })
  }

  const post = async ({
    path,
    payload = {},
    customConfig,
  }: IPostProps): Promise<any> => {
    const config = buildConfig(customConfig)

    return await new Promise((resolve, reject) => {
      httpSecure
        .post(path, payload, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          redirectLogout(error)
          reject(error)
        })
    })
  }

  const put = async ({
    path,
    payload,
    customConfig,
  }: IPutProps): Promise<any> => {
    const config = buildConfig(customConfig)

    return await new Promise((resolve, reject) => {
      httpSecure
        .put(path, payload, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          redirectLogout(error)
          reject(error)
        })
    })
  }

  const patch = async ({
    path,
    payload,
    customConfig,
  }: IPatchProps): Promise<any> => {
    const config = buildConfig(customConfig)

    return await new Promise((resolve, reject) => {
      httpSecure
        .patch(path, payload, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          redirectLogout(error)
          reject(error)
        })
    })
  }

  const deleteRequest = async ({
    path,
    payload,
    customConfig,
  }: IDeleteProps): Promise<any> => {
    const config = buildConfig(customConfig)
    config.data = payload

    return await new Promise((resolve, reject) => {
      httpSecure
        .delete(path, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          redirectLogout(error)
          reject(error)
        })
    })
  }

  return {
    get,
    post,
    put,
    patch,
    deleteRequest,
  }
}

export default useApiSecureAxiosHttpClient
