import Cookies from 'universal-cookie'
import { GLOBAL } from '../../../../core/constants/Global'
import { getResponseType } from '../../../../core/enums/GetResponseType'
import useApiSecureAxiosHttpClient from '../../../../core/http-clients/ApiSecureArielAxiosHttpClient'
import { type IGenericRequestProps } from '../interfaces/IGenericRequestProps'
import {
  type ISendMessagePayload,
  type ICreateChatPayload,
  type IGetChatsHistoryPayload,
  type IGetMessagesPayload,
  type IGetSourcePayload,
  type IGetSuggestedQuestionsPayload,
  type IUpdateChatNamePayload,
  type IUseChatbotService,
  type IAttachFileToChat,
  type IDeleteAttachedFileInChat,
  type IGetInfoNoMessagesPayload,
} from '../interfaces/IUseChatbotService'
import {
  AUTH_COOKIE_NAME,
  AUTH_FINGERPRINT,
  AUTH_USER_ID,
} from '../../../auth/cookies/authCookiesKeys'
import { useChatbotMock } from '../../../../mocks/services/chatbot/useChatbotMock'

const useChatbotService = (): IUseChatbotService => {
  const cookies = new Cookies()

  const { get, post, put, deleteRequest } = useApiSecureAxiosHttpClient()
  const {
    getSuggestedQuestionsMock,
    getSourceMock,
    getChatsHistoryMock,
    getMessagesMock,
    sendMessageMock,
    createChatMock,
    updateChatNameMock,
    deleteChatMock,
  } = useChatbotMock()

  const getSource = async ({
    payload,
    customConfig,
    isMock = false,
  }: IGenericRequestProps<IGetSourcePayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        resolve(getSourceMock)
      })
    }
    return await get({
      path: `resources/${payload.externalId}/chunk/${payload.id}`,
      customConfig,
    })
  }

  const getCategoryTypes = async (): Promise<any> => {
    return await get({
      path: 'resources/tags?category=DOCUMENTS',
    })
  }

  const getCategoryTopics = async (): Promise<any> => {
    return await get({
      path: 'resources/tags?category=TOPIC',
    })
  }

  const getSuggestedQuestions = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<IGetSuggestedQuestionsPayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        resolve(getSuggestedQuestionsMock)
      })
    }
    return await get({
      path: `chat/suggested/chat-setting/${payload.uuid}`,
    })
  }

  const getChatSettings = async (): Promise<any> => {
    return await get({
      path: 'chat/settings',
    })
  }

  const getFilterOptions = async (): Promise<any> => {
    return await get({
      path: 'chat/filters',
    })
  }

  const getChatsHistory = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<IGetChatsHistoryPayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        resolve(getChatsHistoryMock(payload.limit))
      })
    }
    return await get({
      path: `chat/channel?limit=${payload.limit}&page=${payload.page}`,
      responseType: getResponseType.HEADERS_AND_DATA,
    })
  }

  const getInfoNoMessages = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<IGetInfoNoMessagesPayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        resolve(getMessagesMock)
      })
    }
    return await get({
      path: `chat/channel/${payload.uuid}/`,
    })
  }

  const getMessages = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<IGetMessagesPayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        resolve(getMessagesMock)
      })
    }

    return await get({
      path: `chat/channel/${payload.uuid}/messages/?limit=${payload.limit}${payload.cursor ? `&cursor=${payload.cursor}` : ''}`,
      responseType: getResponseType.HEADERS_AND_DATA,
    })
  }

  const getMessageMetadata = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<IGetInfoNoMessagesPayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        resolve(getMessagesMock)
      })
    }
    return await get({
      path: `chat/channel/message/${payload.uuid}/metadata`,
    })
  }

  const createChat = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<ICreateChatPayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        resolve(createChatMock)
      })
    }
    return await post({
      path: 'chat/channel',
      payload,
    })
  }

  const sendMessage = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<ISendMessagePayload>): Promise<any> => {
    if (isMock) {
      sendMessageMock()
    } else {
      return await fetch(
        `${GLOBAL.API_ARIEL_URL}/v1/chat/completions/documents`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${cookies.get(AUTH_COOKIE_NAME)}`,
            User_Id: cookies.get(AUTH_USER_ID) ?? '',
            fingerprint: cookies.get(AUTH_FINGERPRINT),
          },
          body: JSON.stringify(payload),
        },
      )
    }
  }

  const attachFileToChat = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<IAttachFileToChat>): Promise<any> => {
    if (isMock) {
      return 'File has been attached in chat'
    }
    const formData = new FormData()
    const { files, channel_id } = payload

    // // Add each file to formData with the same key name
    // files.forEach((file, _index) => {
    //   formData.append('files', file)
    // })

    formData.append('file', files[0])

    // Add the channel_id separately
    formData.append('channel_id', channel_id)

    return await post({
      path: 'chat/channel/upload-document',
      // path: 'chat/channel/attach-resources',
      customConfig: {
        headers: { 'Content-Type': 'multipart/form-data; charset=utf-8' },
      },
      payload: formData,
    })
  }

  const updateChatName = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<IUpdateChatNamePayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        // setTimeout(() => {
        resolve(updateChatNameMock)
        // }, 10000);
      })
    }
    return await put({
      path: `chat/channel/${payload.uuid}`,
      payload: { name: payload.name },
    })
  }

  const deleteChat = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<string>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        // setTimeout(() => {
        resolve(deleteChatMock)
        // }, 10000);
      })
    }
    return await deleteRequest({
      path: `chat/channel/${payload}`,
    })
  }

  const deleteAttachedFileInChat = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<IDeleteAttachedFileInChat>): Promise<any> => {
    if (isMock) {
      return 'Attached file has been deleted'
    }

    return await deleteRequest({
      path: 'chat/channel/unattach-resource',
      payload,
    })
  }

  return {
    getSource,
    getCategoryTypes,
    getCategoryTopics,
    getChatSettings,
    getSuggestedQuestions,
    getChatsHistory,
    getInfoNoMessages,
    getMessages,
    getMessageMetadata,
    createChat,
    sendMessage,
    attachFileToChat,
    updateChatName,
    deleteChat,
    getFilterOptions,
    deleteAttachedFileInChat,
  }
}

export default useChatbotService
