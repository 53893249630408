import { type PayloadAction } from '@reduxjs/toolkit'
import {
  type IChatHistoryItemOptional,
  type IChatHistoryItem,
  type IChatsHistory,
} from '../../../features/chatbot/chats-history/IChatsHistory'
import {
  type IChatbotMessageMetadata,
  type IChatbotMessage,
} from '../../../../core/components/chatbot/message/interfaces/IChatbotMessage'
import {
  defaulfSelectedFilterValues,
  type FilterFieldKeys,
  type IFilterFieldSelectedValues,
  // type FilterSourceKeys,
  type IFilterSelectedSourcesValues,
} from '../../../features/chatbot/filters/IFilters'
import { mergeMetadata } from '../../../../modules/dashboard/pages/chat/consultation/detail/hooks/useDetailConsultation'

export const chatsHistoryReducers = {
  addChatsToHistory: (
    state: IChatsHistory,
    action: PayloadAction<IChatsHistory>,
  ) => {
    return [...state, ...action.payload]
  },

  addNewChatToHistory: (
    state: IChatsHistory,
    action: PayloadAction<IChatHistoryItem>,
  ) => {
    state.unshift(action.payload)
  },

  updateChatInHistory: (
    state: IChatsHistory,
    action: PayloadAction<{
      uuid: string
      newInfo: IChatHistoryItemOptional
    }>,
  ) => {
    const newState = state.map((item) =>
      item.uuid === action.payload.uuid
        ? {
            ...item,
            ...action.payload.newInfo,
          }
        : item,
    )
    return newState
  },

  addMessageInChatHistory: (
    state: IChatsHistory,
    action: PayloadAction<{
      uuid: string
      message: IChatbotMessage
    }>,
  ) => {
    const newState = state.map((item) =>
      item.uuid === action.payload.uuid
        ? {
            ...item,
            messages: {
              ...item.messages,
              list: [...(item.messages?.list ?? []), action.payload.message],
            },
          }
        : item,
    )
    return newState
  },

  updateMessageInChatHistory: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      messageUuid: string
      chunk?: string
      metadata?: IChatbotMessageMetadata
      isError?: boolean
    }>,
  ) => {
    let newState = state
    try {
      newState = state.map((chat) =>
        chat.uuid === action.payload.chatUuid
          ? {
              ...chat,
              messages: {
                ...chat.messages,
                list: chat.messages?.list?.map((message) =>
                  message.uuid === action.payload.messageUuid
                    ? {
                        ...message,
                        prompt: `${message.prompt}${action.payload.chunk ?? ''}`,
                        metadata: action.payload.metadata
                          ? {
                              ...mergeMetadata(
                                message.metadata ?? {},
                                action.payload.metadata,
                              ),
                              isReviewed: action.payload.metadata.isReviewed,
                            }
                          : message.metadata,
                        isError: action.payload.isError ?? message.isError,
                      }
                    : message,
                ),
              },
            }
          : chat,
      )
    } catch (error) {
      console.error('updateMessageInChatHistory error: ', error)
    }
    return newState
  },

  setIsLoadingPromptResponse: (
    state: IChatsHistory,
    action: PayloadAction<{
      uuid: string
      isLoading: boolean
    }>,
  ) => {
    const newState = state.map((item) =>
      item.uuid === action.payload.uuid
        ? {
            ...item,
            isLoadingResponse: action.payload.isLoading,
          }
        : item,
    )
    return newState
  },

  addMessagesFromPagination: (
    state: IChatsHistory,
    action: PayloadAction<{
      uuid: string
      messages: IChatbotMessage[]
      newCursorPaginationRef: string
    }>,
  ) => {
    const newState = state.map((item) =>
      item.uuid === action.payload.uuid
        ? {
            ...item,
            messages: {
              list: [
                ...action.payload.messages,
                ...(item.messages?.list ?? []),
              ],
              pagination: {
                ...item.messages.pagination,
                cursorPaginationRef: action.payload.newCursorPaginationRef,
              },
            },
          }
        : item,
    )
    return newState
  },

  setAreMessagesPaginationLoading: (
    state: IChatsHistory,
    action: PayloadAction<{
      uuid: string
      isLoading: boolean
    }>,
  ) => {
    const newState = state.map((item) =>
      item.uuid === action.payload.uuid
        ? {
            ...item,
            messages: {
              ...item.messages,
              pagination: {
                ...item.messages.pagination,
                isLoading: action.payload.isLoading,
              },
            },
          }
        : item,
    )
    return newState
  },

  addMetadataToChat: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      messageUuid: string
      metadata: IChatbotMessageMetadata
    }>,
  ) => {
    const newState = state.map((item) =>
      item.uuid === action.payload.chatUuid
        ? {
            ...item,
            messages: {
              ...item.messages,
              list: item.messages.list?.map((message) =>
                action.payload.messageUuid === message.uuid
                  ? {
                      ...message,
                      metadata: action.payload.metadata,
                    }
                  : message,
              ),
            },
          }
        : item,
    )
    return newState
  },

  deleteChatInHistory: (
    state: IChatsHistory,
    action: PayloadAction<string>,
  ) => {
    const newState = state.filter((item) => item.uuid !== action.payload)
    return newState
  },

  updateFilterSelectedValue: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      sourceKey: string
      fieldKey: FilterFieldKeys
      filterValue: Array<string | number> | number | null
    }>,
  ) => {
    const newState = state.map((chat) =>
      chat.uuid === action.payload.chatUuid
        ? {
            ...chat,
            filters: {
              ...chat.filters,
              sources: (chat.filters?.sources ?? []).map((source) =>
                source.key === action.payload.sourceKey
                  ? {
                      ...source,
                      fields: [
                        ...(source.fields ?? []).filter(
                          (filter) => filter.key !== action.payload.fieldKey,
                        ),
                        {
                          key: action.payload.fieldKey,
                          ...(Array.isArray(action.payload.filterValue)
                            ? { options: action.payload.filterValue }
                            : { value: action.payload.filterValue }),
                        },
                      ],
                    }
                  : source,
              ),
            },
          }
        : chat,
    )
    return newState
  },

  addSourcesToChat: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      sources: IFilterSelectedSourcesValues[]
    }>,
  ) => {
    const newState = state.map((chat) =>
      chat.uuid === action.payload.chatUuid
        ? {
            ...chat,
            filters: {
              ...chat.filters,
              sources: [
                ...(chat.filters?.sources ?? []),
                ...action.payload.sources,
              ],
            },
          }
        : chat,
    )
    return newState
  },

  removeSourceFromChat: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      source: IFilterSelectedSourcesValues
    }>,
  ) => {
    const newState = state.map((chat) =>
      chat.uuid === action.payload.chatUuid
        ? {
            ...chat,
            filters: {
              ...chat.filters,
              sources:
                chat.filters?.sources?.filter(
                  (source) =>
                    !(
                      source.key === action.payload.source.key &&
                      source.id === action.payload.source.id
                    ),
                ) ?? [],
            },
          }
        : chat,
    )
    return newState
  },

  updateSourceActiveStatus: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      sourceKey: string
      sourceLabel: string
      ownerId?: string
      active: boolean
      fields: IFilterFieldSelectedValues[]
    }>,
  ) => {
    const newState = state.map((chat) => {
      if (chat.uuid === action.payload.chatUuid) {
        const existingSources = chat.filters?.sources ?? []
        const sourceIndex = existingSources.findIndex(
          (s) => s.key === action.payload.sourceKey,
        )

        const newSources = [...existingSources]

        if (sourceIndex === -1) {
          // Source doesn't exist, add it with initial filters
          newSources.push({
            key: action.payload.sourceKey,
            label: action.payload.sourceLabel,
            active: action.payload.active,
            owner_id: action.payload.ownerId,
            fields: action.payload.fields,
          })
        } else {
          // Source exists, update only its active status
          newSources[sourceIndex] = {
            ...newSources[sourceIndex],
            active: action.payload.active,
            // Keep existing filters
            fields:
              newSources[sourceIndex].fields?.length > 0
                ? newSources[sourceIndex].fields
                : action.payload.fields,
          }
        }

        return {
          ...chat,
          filters: {
            ...chat.filters,
            sources: newSources,
          },
        }
      }
      return chat
    })
    return newState
  },

  clearFilters: (
    state: IChatsHistory,
    action: PayloadAction<{
      chatUuid: string
      sourceKey?: string
      all?: boolean
    }>,
  ) => {
    const newState = state.map((chat) => {
      if (chat.uuid === action.payload.chatUuid) {
        if (action.payload.all) {
          return {
            ...chat,
            filters: defaulfSelectedFilterValues,
          }
        }

        if (action.payload.sourceKey) {
          return {
            ...chat,
            filters: {
              ...chat.filters,
              sources:
                chat.filters?.sources?.map((source) =>
                  source.key === action.payload.sourceKey
                    ? { ...source, fields: [] }
                    : source,
                ) ?? [],
            },
          }
        }
      }
      return chat
    })
    return newState
  },

  resetChatsHistory: (
    _: IChatsHistory,
    action: PayloadAction<{
      newHistory: IChatHistoryItem[]
    }>,
  ) => {
    return action.payload.newHistory
  },
}
