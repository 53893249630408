import { createSlice } from '@reduxjs/toolkit'
import { type IFilterSourcesOptions } from './IFilters'

export const initialState: IFilterSourcesOptions[] = []

const filterOptionsSlice = createSlice({
  initialState,
  name: 'filterOptions',
  reducers: {
    setFilterOptions: (_state, action) => {
      return [...action.payload]
    },
  },
})

export default filterOptionsSlice.reducer
export const { setFilterOptions } = filterOptionsSlice.actions
