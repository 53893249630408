export const ES_TRANSLATIONS = {
  ES: 'Español',
  DASHBOARD: {
    EMPTY_STATE: {
      ROOT: {
        DEMO_COMPLETED: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Compra algunos de nuestros planes y sigue disfrutando de Ariel:',
          CTA: 'Ver planes',
        },
        DEMO: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Compra algunos de nuestros planes y sigue disfrutando de Ariel:',
          CTA: 'Ver planes',
        },
        PAYMENT_DECLINED: {
          TITLE: 'El pago de tu renovación no pudo ser procesado',
          DESCRIPTION:
            'Reactiva tu cuenta completando el pago de tu plan a través del siguiente link.',
          CTA: 'Pagar ahora',
        },
      },
      ADMIN: {
        DEMO_COMPLETED: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Compra algunos de nuestros planes y sigue disfrutando de Ariel:',
          CTA: 'Ver planes',
        },
        DEMO: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Compra algunos de nuestros planes y sigue disfrutando de Ariel:',
          CTA: 'Ver planes',
        },
        PAYMENT_DECLINED: {
          TITLE: 'El pago de tu renovación no pudo ser procesado',
          DESCRIPTION:
            'Reactiva tu cuenta completando el pago de tu plan a través del siguiente link.',
          CTA: 'Pagar ahora',
        },
      },
      USER: {
        DEMO_COMPLETED: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Comunícate con el propietario de la organización para adquirir alguno de nuestros planes y seguir disfrutando de Ariel.',
        },
        DEMO: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Comunícate con el propietario de la organización para adquirir alguno de nuestros planes y seguir disfrutando de Ariel.',
        },
        PAYMENT_DECLINED: {
          TITLE: 'El pago de tu renovación no pudo ser procesado',
          DESCRIPTION:
            'Reactiva tu cuenta completando el pago de tu plan a través del siguiente link.',
          CTA: 'Pagar ahora',
        },
      },
      CTAS: {
        CHILD: 'Ver planes',
      },
    },
  },

  NAVBAR: {
    MENU_CHIPS: {
      HOME: 'Inicio',
      LIBRARY: 'Biblioteca',
      MY_DOCUMENTS: 'Mis documentos',
      TRANSCRIPTIONS: 'Transcripciones',
      BURGER_MENU: 'Menú',
      NEW_CONSULTATION: 'Nueva consulta',
      CONSULTATIONS: 'Consultar',
    },
    CONVERSATIONS: 'Consultas',
    MENU: 'Menú',
  },
  UPGRADE_CARD: {
    TITLE: {
      TRY_FREE: 'Prueba gratis',
    },
    DESCRIPTION: {
      REMINDER_TIME_LEFT:
        'El tiempo de acceso gratis terminará dentro de <days>{{days}}</days>.',
      BUY_PLAN: 'Compra un plan y disfruta Ariel sin límite de tiempo',
      UPLOAD_DOCS_TO_LIBRARY:
        'Construye tu propia biblioteca de documentos e intégrala con un modelo personalizado de Ariel.',
    },
    CTA: {
      BUY_PLAN: 'Comprar plan',
      SEE_PLANS: 'Ver planes',
      GO_TO_LIBRARY: 'Contáctanos',
    },
    DAYS: 'días',
    DAY: 'día',
  },
  TIME: {
    MINUTES: 'minutos',
    HOURS: 'horas',
  },
  TRANSCRIPTIONS: {
    TITLE: 'Transcripciones',
    CTA: 'Subir',
    DATA_ZERO: {
      TITLE: 'Transcribe a texto tu primer audio',
      DESCRIPTION:
        'Sube un archivo de audio para transcribirlo a texto. Luego podrás hacerle consultas a Ariel acerca de ese audio.',
      CTA: 'Subir audio',
    },
    UPLOAD_AUDIO: {
      TITLE: 'Transcribir audio',
      DESCRIPTION: 'Arrastra el archivo aquí',
      VALID_EXTENSIONS: 'Archivo en formato MP3',
      // VALID_EXTENSIONS: "Formato MP3, MP4, WAV de máximo 5MB.",
      FIELD: {
        TITLE: {
          AUDIO_FILE: 'Archivo de audio',
          TRANSCRIPTION_NAME: 'Nombre de la transcripción',
        },
      },
      CTAS: {
        SELECT: 'Seleccionar archivo',
        TRANSCRIBE: 'Transcribir',
      },
      MESSAGES: {
        REMAINING_TIME:
          'A tu organización le quedan <time>{{time}}</time> de transcripciones este mes. ¿Necesitas más? <linkToUpgrade>Aumentar horas</linkToUpgrade>',
        TIME_EXCEEDED:
          'Este audio excede las horas de transcripción disponibles este mes en tu organización.',
      },
    },
    DETAIL: {
      TYPE: {
        TRANSCRIPTION: 'Transcripción',
        CHAT: 'Chat',
      },
      NOT_FOUND: 'Transcripción no encontrada',
      SUGGESTED_QUESTIONS: {
        TITLE: 'Sugerencias',
        GENERAL_SUMMARY: 'Dame un resumen de la transcripción',
        FIVE_POINTS_SUMMARY:
          'Dame los 5 puntos más importantes de la transcripción',
      },
    },
  },
  EMAIL_VALIDATION: {
    VALIDATE_EMAIL: {
      TITLE: 'Revisa tu bandeja de entrada',
      DESCRIPTION:
        'Hemos enviado un correo de verificación a {{email}}, entra al link para verificar tu email. En caso de no encontrar el correo revisa tus correos no deseados.',
    },
    EMAIL_VERIFIED: {
      TITLE: 'Email verificado',
      DESCRIPTION:
        'Gracias por verificar tu email. Ahora empecemos completando tu perfil.',
      CTA: 'Empezar',
    },
    VALIDATION_FAILED: {
      TITLE: 'Hubo un problema con la verificación',
      DESCRIPTION:
        'Vuelve a entrar al link del correo de verificación. Si el problema persisten, ponte en contacto con soporte.',
    },
  },
  REGISTRATION: {
    CREATE_PROFILE: {
      TITLE: 'Crea tu perfil',
    },
    CREATE_COMPANY: {
      TITLE: 'Crea una nueva organización',
      DESCRIPTION:
        'Empieza con una nueva organización e invita a tus compañeros.',
      INITIAL_VALUE: 'Organización de {{name}}',
    },
    PRICING: {
      TITLE: 'Escoge un plan a probar por 3 días gratis',
      DESCRIPTION:
        'Inicia tu prueba gratis con algunos de nuestro planes. Podrás cambiar de plan en cualquier momento.',
      PAYMENT: {
        MONTHLY: 'Pago mensual',
        YEARLY: 'Pago anual',
      },
      PROMO: 'Lleva 2 meses GRATIS pagando anual',
      TIERS: {
        NO_CARD_NEEDED: 'Sin tarjeta de crédito',
        PRICE_PER_MEMBER: '$<comp>{{price}}</comp> por usuario',
        CURRENCY: 'USD por mes',
        BASE_PRICE: 'Pago anual de $<comp>{{price}}</comp> USD',
        CTAS: {
          FREE_TRIAL: 'Probar GRATIS 3 días',
          CONTACT: 'Contactar Ventas',
        },
        COMPANY: {
          TITLE: 'Organización',
          SUBTITLE: '10 o más usuarios',
          DESCRIPTION:
            'Para equipos de más de 10 personas, contacta nuestro equipo de ventas para recibir un plan a la medida.',
          YEARLY: {
            UNIT_PRICE: 'A la medida',
            BASE_PRICE: 'Contacta nuestro equipo de ventas',
          },
          MONTHLY: {
            UNIT_PRICE: 'A la medida',
            BASE_PRICE: 'Contacta nuestro equipo de ventas',
          },
        },
      },
    },
    INVITE: {
      TITLE: 'Invita a los miembros de tu organización',
      DESCRIPTION:
        'Los miembros recibirán un correo de invitación con el que podrán crear su cuenta asociada a tu organización.',
    },
    COMPLETED: {
      TITLE: '¡Ya está todo listo!',
      DESCRIPTION:
        'Tendrás acceso a Ariel hasta el día <date>{{date}}<date/>. En cualquier momento podrás comprar un plan para extender tu acceso y el de los miembros de tu organización.',
    },
    PLANS: {
      PERSONAL: 'Personal: 1 usuario',
      TEAM3: 'Trío: 3 usuarios',
      TEAM5: 'Equipo: 5 usuarios',
      COMPANY: 'Organización: 10 o más usuarios',
    },
  },
  DEVICES_LIMIT_REACHED: {
    DISCONNECT: {
      TITLE: 'Límite de dispositivos alcanzado',
      DESCRIPTION:
        'Por tu seguridad, Ariel permite el acceso a tu cuenta desde un máximo de 2 dispositivos. Para ingresar con un nuevo dispositivo, desvincula alguno y enviaremos un código a tu correo para garantizar que solo tú puedas acceder a tu información.',
      LIST: {
        TITLE: 'Dispositivos vinculados',
      },
    },
    VERIFICATION: {
      TITLE: 'Ingresa el código de verificación',
      DESCRIPTION:
        'Hemos enviado un código de verificación a tu correo {{email}}.',
    },
    CTAS: {
      DISCONNECT: 'Desvincular',
      NEXT: 'Siguiente',
    },
  },
  MEMBERS_LIST: {
    OWNER: 'Propietario',
    USER: 'Miembro',
    ADMIN: 'Admin',
  },
  FORMS: {
    LABELS: {
      NAMES: 'Nombres',
      NAME: 'Nombre',
      EMAIL: 'Email',
      SECURITY: 'Seguridad',
      DANGER_ZONE: 'Zona de peligro',
      LAST_NAME: 'Apellidos',
      COMPANY: 'Organización',
      PLAN: 'Plan',
      MEMBERS: 'Miembros',
      PHONE: 'Numero de teléfono',
      VERIFICATION: 'Código de verificación',
      FROM: 'Desde',
      TO: 'Hasta',
      VERIFICATION_CODE: 'Código de verificación',
    },
    PLACEHOLDERS: {
      NAMES: 'Nombres',
      NAME: 'Nombre',
      EMAIL: 'Email',
      LAST_NAME: 'Apellidos',
      PHONE: 'Teléfono',
      CURRENT_DATE: 'Actualidad',
      CODE: 'Código',
    },
    UPLOAD_IMAGE: {
      UPLOAD: 'Subir imagen',
      CHANGE: 'Cambiar imagen',
      REMOVE: 'Quitar',
    },
    CONTROLS: {
      EDIT: 'Editar',
      CANCEL: 'Cancelar',
      SAVE: 'Guardar',
    },
    BLUR: {
      INVALID_EMAIL: 'Email inválido',
    },
    CTAS: {
      NEXT: 'Siguiente',
      START: 'Empezar',
      CHANGE_PASSWORD: 'Cambiar contraseña',
      DELETE_ACCOUNT: 'Eliminar cuenta',
    },
  },
  CHATBOT: {
    CONSULTATIONS: 'Consultas',
    NEW_CONSULTATION: 'Nueva consulta',
    CREATE_CONSULTATION: {
      GREET: '¡Hola {{userName}}!',
      DESCRIPTION: '¿Qué tipo de consulta quieres hacer?',
    },
    SUGGESTIONS: 'Sugerencias',
    SOURCES_LABEL: 'Citas',
    SOURCES_LABEL_2: 'Fuentes',
    SEE_ALL: 'Ver fuentes',
    FILTERS_BTN: 'Controles',
    LOADING: {
      GENERATING_INITIAL_SEARCH: 'Generando búsqueda inicial',
      IMPROVING_SEARCH: 'Mejorando la búsqueda',
      ANALYZING_DOCUMENTS: 'Analizando los documentos',
      GENERATING_RESPONSE: 'Generando respuesta',
    },
    CLAUSE:
      'Las respuestas de Ariel no constituyen una recomendación legal ni remplazan una asesoría.',
    PLACEHOLDER: 'Escribe tu mensaje...',
    PLACEHOLDER_DISABLED: 'Deshabilitado temporalmente',
    MESSAGE: {
      ERROR: {
        TITLE: 'Error en la solicitud.',
        DESCRIPTION: 'Error {{code}}: {{message}}',
        UNKNOWN: 'Error desconocido',
        UNABLE_CONNECTION: 'Unable to connect to the server',
        INVALID_MESSAGE: 'Mensaje no válido',
      },
    },
    SOURCES: {
      TITLE_AND_PAGE: '{{title}} - Página {{page}}',
      TITLE: 'Título',
      PAGE: 'Página',
    },
    ATTACHED_FILE: {
      UPLOAD_FROM_COMPUTER: 'Subir desde tu computador',
      MAX_FILES_REACHED: 'Puedes tener solo 1 documento por consulta',
      CHIP: {
        BETA: 'Funcionalidad en Beta',
      },
      UPLOAD_BTN: {
        TITLE: 'Adjuntar',
        LOADING: 'Cargando ...',
        LOADED_BASE: 'Documento',
      },
      NO_ACTIVE_TIER:
        'Adquiere uno de nuestros planes para utilizar esta funcionalidad.',
      PDF: 'PDF',
    },
  },
  FILTERS: {
    GENERAL: {
      TITLE: 'Filtros',
      LIBRARY_FILTERS: {
        ARIEL: {
          NAME: 'Ariel',
          DESCRIPTION: '{{total}}k documentos',
        },
        CUSTOM_ORGANIZATION: {
          NAME: 'Organización de {{organizationName}}',
          DESCRIPTION: '{{total}} documentos',
        },
      },
    },
    ATTACHMENTS: {
      TITLE: 'Adjuntos',
      EMPTY: {
        TITLE: 'No hay documentos adjuntos',
        DESCRIPTION:
          'Adjunta tus PDF para que Ariel pueda hacer consultas sobre ellos.',
      },
    },
    DOCS: {
      TITLE: 'Filtrar por',
    },
    CTAS: {
      CLEAR: 'Limpiar filtros',
    },
    AUTHORS: {
      SEARCH_PLACEHOLDER: 'Buscar autor...',
    },
  },
  ACCORDION: {
    TITLE: {
      YEAR: 'Año',
      TYPE: 'Categoría legal',
      TOPICS: 'Área del derecho',
      AUTHORS: 'Autor',
      TAGS: 'Etiqueta',
      COMPANY_CATEGORIES: 'Categoría interna',
    },
    DESCRIPTION: {
      YEAR: {
        FROM: 'Desde {{from}}',
        TO: 'Hasta {{to}}',
      },
    },
  },
  TOOLTIP: {
    COPY: 'Copiar',
    DOWNLOAD: 'Descargar',
    PAGE: 'Página {{page}}',
    UPLOAD_FILE: 'Subir documento',
  },
  MESSAGES: {
    ASK_ABOUT_TRANSCRIPTION:
      'Hazle preguntas a Ariel acerca de esta transcripción.',
    ATTACH_FILE_IN_CHAT: 'Funcionalidad en Beta Experimental',
  },
  CTAS: {
    OPEN: 'Abrir',
    ENTER: 'Ingresar',
    LOG_IN: 'Iniciar sesión',
    NEXT: 'Siguiente',
  },
  ASIDE: {
    TITLE: {
      SOURCES_CONSULTED: 'Fuentes consultadas',
      FILTERS: 'Controles de consulta',
    },
    REFERENCED_FRAGMENT: 'Fragmento referenciado',
    PAGE_NUMBER: 'Página {{numberOfPage}}',
    LOADING: 'Cargando Fuente ',
    CTAS: {
      OPEN: 'Descargar PDF',
    },
  },
  TOAST: {
    SEE: 'Ver',
    RETRY: 'Reintentar',
    TEXT_COPIED: 'Texto copiado',
    WRONG_EXTENSION: 'Formato no válido. Asegúrate de que sea un PDF.',
    SIZE_EXCEEDED:
      'El archivo supera los 20MB, Asegúrate que sea menor a 20MB.',
    REGISTERED_DEVICE: 'Dispositivo vinculado',
    EMAIL_SENT_RESET_PASSWORD:
      'Hemos enviado un correo con las indicaciones para cambiar la contraseña.',
    ERROR_EMAIL_SENT_RESET_PASSWORD:
      'No se ha podido enviar el correo para cambiar la contraseña.',
    ACCOUNT_DELETED_SUCCESSFULLY: 'Tu cuenta ha sido eliminada',
    ATTACH_FILE_ERROR: 'No se pudo adjuntar el documento',
    DELETE_ACCOUNT_FAILED: 'Tu cuenta no pudo ser eliminada',
    TOTAL_FILES_LIMIT: 'Máximo alcanzado de 3 documentos al tiempo',
    FILES_TOO_LARGE: 'Máximo alcanzado de 20MB al tiempo',
  },
  MODAL: {
    TITLE: {
      GENERAL_CONVERSATION: 'Nombre de la consulta',
      TRANSCRIPTION_CONVERSATION: 'Nombre de la transcripción',
      ATTACH_FILE_IN_CHAT: 'Subir documento',
    },
    DESCRIPTION: {
      ATTACH_FILE_IN_CHAT:
        'Archivo en formato PDF. Máximo de 20MB y 10,000 tokens (10 páginas aproximadamente)',
    },
    ACTION: {
      DRAG_FILE: 'Arrastra el archivo aquí',
      DOWNLOAD_ALL_SOURCES: 'Descargar citas en Word',
    },
    CTAS: {
      CANCEL: 'Cancelar',
      SAVE: 'Guardar',
      SELECT_FILE: 'Seleccionar archivo',
    },
  },
  ALERT: {
    TITLE: {
      DELETE_CONVERSATION: 'Eliminar consulta',
      DELETE_TRANSCRIPTION: 'Eliminar transcripción',
      DELETE_ATTACHED_FILE_IN_CHAT: 'Eliminar documento',
      DELETE_ACCOUNT: 'Eliminar cuenta',
    },
    DESCRIPTION: {
      DELETE_CONVERSATION:
        '¿Estás seguro de eliminar <1>"{{conversationName}}"</1>? Esta acción no se podrá revertir.',
      DELETE_ATTACHED_FILE_IN_CHAT: 'Ariel no podrá consultar este documento.',
      DELETE_ACCOUNT:
        'Esta acción es irreversible. Al eliminar tu cuenta, perderás acceso a todos tus datos, configuraciones y contenido asociado. ¿Deseas continuar?',
    },
    CTAS: {
      CANCEL: 'Cancelar',
      DELETE: 'Eliminar',
    },
  },
  TABLE: {
    NAME: 'NOMBRE',
    DATE: 'SUBIDO EL',
    STATUS: {
      PROCESSING: 'Procesando',
      PENDING: 'Pendiente',
      ERROR: 'Error',
    },
  },
  DROPDOWN: {
    CONVERSATION: {
      EDIT_NAME: 'Editar nombre',
      DELETE: 'Eliminar consulta',
    },
    TRANSCRIPTION: {
      EDIT_NAME: 'Editar nombre',
      DELETE: 'Eliminar transcripción',
    },
    ROLS: {
      MEMBER: 'Miembro',
      ADMIN: 'Admin',
    },
  },
  CHECKBOX_OPTIONS: {
    ALL_CATEGORIES: 'Todas las categorías',
    ALL_TOPICS: 'Todas las áreas',
    ALL_COMPANY_CATEGORIES: 'Todas las categorías internas',
    ALL_TAGS: 'Todas las etiquetas',
    ALL_AUTHORS: 'Todos los autores',
  },
  TABS: {
    TRANSCRIPTION: 'Transcripción',
    QUERIES: 'Chat',
  },
  ACCOUNT_SETTINGS: {
    ACCOUNT: {
      CHIPS: {
        TITLE: 'CUENTA',
        MY_ACCOUNT: 'Mi cuenta',
      },
      CONTENT: {
        TITLE: 'Mi cuenta',
      },
    },
    ORGANIZATION: {
      CHIPS: {
        TITLE: 'ORGANIZACIÓN',
        MY_ORGANIZATION: 'Mi organización',
        MEMBERS: 'Miembros',
        PLANS_AND_PAYMENTS: 'Gestión de pagos',
      },
      CONTENT: {
        MY_ORGANIZATION: {
          TITLE: 'Mi organización',
        },
        MEMBERS: {
          TITLE: 'Mi organización',
        },
        PLANS_AND_PAYMENTS: {
          TITLE: 'Mi organización',
        },
      },
    },
  },
  ERRORS: {
    ERROR_OCCURRED: 'Ocurrió un error',
    ERROR_SERVER: 'Error de servidor',
    INVALID_EMAIL: 'Email no válido',
    INCORRECT_PHONE_NUMBER: 'Número de teléfono no válido',
    SIZE_EXCEEDED:
      'El archivo supera las 25 MB. Prueba con un audio más ligero.',
    AUDIO_FORMAT:
      'Formato de archivo no soportado. Intenta con otro archivo de audio.',
    IMAGE_FORMAT:
      'Formato de archivo no soportado. Intenta con otro archivo de imagen.',
    EMPTY_FIELDS: 'Algunos campos están vacíos',
    INVALID_CODE: 'Código inválido',
    CAN_NOT_DELETE_DEVICES:
      'No fue posible eliminar los dispositivos seleccionados',
    SOMETHING_WENT_WRONG: 'Algo salió mal. No pudimos guardar los cambios',
    ERROR_BOUNDARY: 'Algo sucedió. Por favor, refresca o cambia de página',
  },
  FOOTER: {
    SETTINGS: 'Ajustes',
    SUPPORT: 'Contactar soporte',
    SUGGESTIONS: 'Dejar una sugerencia',
    TERMS_AND_CONDITIONS: 'Términos & Condiciones',
    PRIVICY_POLICIES: 'Políticas de privacidad',
    CLOSE_SESSION: 'Cerrar sesión',
    COPYRIGHT: '©2024 Ariel',
  },
}
