import { createSlice } from '@reduxjs/toolkit'
import { type IChatsHistory } from './IChatsHistory'
import { chatsHistoryReducers } from '../../../reducers/chatbot/chats-history/ChatsHistoryReducers'

export const initialState: IChatsHistory = []

const chatsHistorySlice = createSlice({
  name: 'chatsHistory',
  initialState,
  reducers: chatsHistoryReducers,
})

export default chatsHistorySlice.reducer
export const {
  addChatsToHistory,
  addNewChatToHistory,
  updateChatInHistory,
  addMessageInChatHistory,
  updateMessageInChatHistory,
  setIsLoadingPromptResponse,
  addMessagesFromPagination,
  setAreMessagesPaginationLoading,
  addMetadataToChat,
  deleteChatInHistory,
  updateFilterSelectedValue,
  updateSourceActiveStatus,
  addSourcesToChat,
  removeSourceFromChat,
  clearFilters,
  resetChatsHistory,
} = chatsHistorySlice.actions
