import React from 'react'
import Message from '../app/core/components/message/Message'

interface ErrorBoundaryProps {
  children: React.ReactNode
  hasError: boolean
  setError: () => void
  errorDescription: string
}

interface ErrorBoundaryState {
  errorResponse: string
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { errorResponse: '' }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { errorResponse: `${error.name}: ${error.message}` }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('ErrorBoundary caught an error', error, errorInfo)
    this.props.setError()
  }

  render(): React.ReactNode {
    if (this.props.hasError) {
      return (
        <div style={{ margin: '20px', maxWidth: '450px' }}>
          <Message
            description={
              <>
                <div>{this.props.errorDescription}</div>
                <div style={{ marginTop: '20px', fontStyle: 'italic' }}>
                  {this.state.errorResponse}
                </div>
              </>
            }
          />
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
