import { type PayloadAction } from '@reduxjs/toolkit'
import { type IChatbotMessage } from '../../../core/components/chatbot/message/interfaces/IChatbotMessage'
import {
  type ITranscriptionDetail,
  type ITranscriptionDetailOptional,
  type ITranscriptionsTable,
} from '../../../modules/auth/interfaces/ITranscriptionsTable'

export const TranscriptionsTableReducers = {
  setTrancriptionsByPage: (
    state: ITranscriptionsTable,
    action: PayloadAction<{ page: string; items: any[] }>,
  ) => {
    state.transcriptionsByPages[`page${action.payload.page}`] =
      action.payload.items
  },

  addTranscription: (
    state: ITranscriptionsTable,
    action: PayloadAction<{ item: any }>,
  ) => {
    const allTranscriptions = Object.values(state.transcriptionsByPages).reduce(
      (acc, curr) => acc.concat(curr),
      [],
    )

    allTranscriptions.unshift(action.payload.item)

    const transcriptionsByPages: any[] = []
    for (let i = 0; i < allTranscriptions.length; i += 20) {
      transcriptionsByPages.push(allTranscriptions.slice(i, i + 20))
    }

    const newState = transcriptionsByPages.reduce((acc, curr, index) => {
      acc[`page${index}`] = curr
      return acc
    }, {})

    state.transcriptionsByPages = newState
  },

  removeTranscription: (
    state: ITranscriptionsTable,
    action: PayloadAction<{ uuid: string }>,
  ) => {
    const allTranscriptions = Object.values(state.transcriptionsByPages).reduce(
      (acc, curr) => acc.concat(curr),
      [],
    )

    const allTranscriptionsFiltered = allTranscriptions.filter(
      (item) => item.uuid !== action.payload.uuid,
    )

    const transcriptionsByPages: any[] = []
    for (let i = 0; i < allTranscriptionsFiltered.length; i += 20) {
      transcriptionsByPages.push(allTranscriptionsFiltered.slice(i, i + 20))
    }

    const newState = transcriptionsByPages.reduce((acc, curr, index) => {
      acc[`page${index}`] = curr
      return acc
    }, {})

    state.transcriptionsByPages = newState
  },

  updateTranscriptionName: (
    state: ITranscriptionsTable,
    action: PayloadAction<{ uuid: string; newName: string; page: string }>,
  ) => {
    const newState = state.transcriptionsByPages[
      `page${action.payload.page}`
    ].map((item) =>
      item.uuid === action.payload.uuid
        ? { ...item, name: action.payload.newName }
        : item,
    )

    state.transcriptionsByPages[`page${action.payload.page}`] = newState
  },

  setPendingOrProcessingList: (
    state: ITranscriptionsTable,
    action: PayloadAction<any[]>,
  ) => {
    state.pendingOrProcessingList = action.payload
  },

  setTranscriptionsTotal: (
    state: ITranscriptionsTable,
    action: PayloadAction<any>,
  ) => {
    state.transcriptionsTotal = action.payload
  },

  setCurrentPage: (state: ITranscriptionsTable, action: PayloadAction<any>) => {
    state.currentPage = action.payload
  },

  updateIsInitialRequestDone: (
    state: ITranscriptionsTable,
    action: PayloadAction<any>,
  ) => {
    state.isInitialRequestDone = action.payload
  },

  setAreTranscriptionsLoading: (
    state: ITranscriptionsTable,
    action: PayloadAction<boolean>,
  ) => {
    state.areTranscriptionsLoading = action.payload
  },

  addItemInTranscriptionsDetail: (
    state: ITranscriptionsTable,
    action: PayloadAction<ITranscriptionDetail>,
  ) => {
    state.transcriptionsDetail.push(action.payload)
  },

  addMessagesInTranscriptionsDetail: (
    state: ITranscriptionsTable,
    action: PayloadAction<{
      uuid: string
      messages: IChatbotMessage[]
      newCursorPaginationRef: string
    }>,
  ) => {
    const newState = state.transcriptionsDetail.map((item) =>
      item.uuid === action.payload.uuid
        ? {
            ...item,
            messages: {
              list: action.payload.messages,
              pagination: {
                ...item.messages.pagination,
                cursorPaginationRef: action.payload.newCursorPaginationRef,
              },
            },
            isChannelReviewed: true,
          }
        : item,
    )
    state.transcriptionsDetail = newState
  },

  addMessageInTranscriptionsDetail: (
    state: ITranscriptionsTable,
    action: PayloadAction<{ channelId: string; message: IChatbotMessage }>,
  ) => {
    const newState = state.transcriptionsDetail.map((item) =>
      item.channelId === action.payload.channelId
        ? {
            ...item,
            messages: {
              ...item.messages,
              list: [...item.messages.list, action.payload.message],
            },
          }
        : item,
    )
    state.transcriptionsDetail = newState
  },

  updateTranscriptionInTranscriptionsDetail: (
    state: ITranscriptionsTable,
    action: PayloadAction<{
      searchFor: 'uuid' | 'channelId'
      uuidValue: string
      newInfo: ITranscriptionDetailOptional
    }>,
  ) => {
    const newState = state.transcriptionsDetail.map((item) =>
      item[action.payload.searchFor] === action.payload.uuidValue
        ? {
            ...item,
            ...action.payload.newInfo,
          }
        : item,
    )
    state.transcriptionsDetail = newState
  },

  setAreMessagesPaginationLoading: (
    state: ITranscriptionsTable,
    action: PayloadAction<{
      chatUuid: string
      isLoading: boolean
    }>,
  ) => {
    const newState = state.transcriptionsDetail.map((item) =>
      item.channelId === action.payload.chatUuid
        ? {
            ...item,
            messages: {
              ...item.messages,
              pagination: {
                ...item.messages.pagination,
                isLoading: action.payload.isLoading,
              },
            },
          }
        : item,
    )
    state.transcriptionsDetail = newState
  },

  addMessagesFromPagination: (
    state: ITranscriptionsTable,
    action: PayloadAction<{
      chatUuid: string
      messages: IChatbotMessage[]
      newCursorPaginationRef: string
    }>,
  ) => {
    const newState = state.transcriptionsDetail.map((item) =>
      item.channelId === action.payload.chatUuid
        ? {
            ...item,
            messages: {
              list: [
                ...action.payload.messages,
                ...(item.messages?.list ?? []),
              ],
              pagination: {
                ...item.messages.pagination,
                cursorPaginationRef: action.payload.newCursorPaginationRef,
              },
            },
          }
        : item,
    )
    console.log('newState', newState)

    state.transcriptionsDetail = newState
  },

  removeItemInTranscriptionsDetail: (
    state: ITranscriptionsTable,
    action: PayloadAction<{ uuid: string }>,
  ) => {
    state.transcriptionsDetail = state.transcriptionsDetail.filter(
      (item) => item.uuid !== action.payload.uuid,
    )
  },

  clearTranscriptionsList: (state: ITranscriptionsTable) => {
    state.transcriptionsByPages = {}
  },

  setIsLoadingResponseFromTranscription: (
    state: ITranscriptionsTable,
    action: PayloadAction<{ channelId: string; isLoading: boolean }>,
  ) => {
    const newState = state.transcriptionsDetail.map((item) =>
      item.channelId === action.payload.channelId
        ? {
            ...item,
            isLoadingResponse: action.payload.isLoading,
          }
        : item,
    )
    state.transcriptionsDetail = newState
  },
}
