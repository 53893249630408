import { useEffect, useRef, useState } from 'react'
import {
  type IUseIntersectionObserver,
  type ISetIntersectionConfig,
} from './IUseIntersectionObserver'

const useIntersectionObserver = (): IUseIntersectionObserver => {
  const [observerEl, setObserverEl] = useState<null | Element>(null)
  const [targetEl, setTargetEl] = useState<null | Element | Element[]>(null)
  const [options, setOptions] = useState<IntersectionObserverInit | null>(null)
  const intersectionRef = useRef<null | IntersectionObserver>(null)
  const actionRef = useRef<any>(null)

  useEffect(() => {
    if (observerEl) {
      setOptions({
        root: observerEl,
      })
    }
  }, [observerEl])

  useEffect(() => {
    if (options?.root && actionRef.current) {
      intersectionRef.current = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]): void => {
          entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
              actionRef.current(entry)
            }
          })
        },
        options,
      )

      if (intersectionRef.current) {
        if (targetEl) {
          if (Array.isArray(targetEl)) {
            targetEl.forEach((el) => {
              intersectionRef.current?.observe(el)
            })
          } else {
            intersectionRef.current.observe(targetEl)
          }
        }
      }
    }

    return () => {
      if (intersectionRef.current) {
        intersectionRef.current.disconnect()
      }
    }
  }, [observerEl, targetEl, options])

  const setIntersectionConfig = ({
    observerEl,
    targetEl,
    action,
    customOptions,
  }: ISetIntersectionConfig): void => {
    setObserverEl(observerEl)
    setTargetEl(targetEl)
    actionRef.current = action
    if (customOptions) {
      setOptions((prev) => ({
        ...prev,
        ...customOptions,
      }))
    }
  }

  return {
    setIntersectionConfig,
  }
}

export default useIntersectionObserver
