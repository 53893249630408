import { type PayloadAction } from '@reduxjs/toolkit'
import { type IAlert } from '../../features/alert/IAlert'
import { initialStateAlert } from '../../features/alert/AlertSlice'

export const alertReducers = {
  showAlert: (state: IAlert, action: PayloadAction<IAlert>) => {
    state.isVisible = action.payload.isVisible
    state.title = action.payload.title
    state.description = action.payload.description
    state.actionLabel = action.payload.actionLabel
    state.actionType = action.payload.actionType
    state.actionParams = action.payload.actionParams
    state.additionalInfo = action.payload.additionalInfo
  },

  hideAlert: (state: IAlert) => {
    Object.entries(initialStateAlert).forEach(([key, value]) => {
      state[key] = value
    })
  },
}
