import { createSlice } from '@reduxjs/toolkit'
import { type ICompany } from '../../../modules/auth/interfaces/ICompany'
import { companyReducer } from '../../reducers/company/CompanyReducers'

const initialState: ICompany | any = {}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: companyReducer,
})

export const { setCompany, updateCompanyState } = companySlice.actions

export default companySlice.reducer
