import { type PayloadAction } from '@reduxjs/toolkit'
import { type ICompany } from '../../../modules/auth/interfaces/ICompany'
import { type AllOptionalProperties } from '../../../core/types/AllOptionalProperties'

export const companyReducer = {
  setCompany: (
    state: ICompany | any,
    action: PayloadAction<ICompany> | any,
  ) => {
    Object.keys(action.payload).forEach((key: string) => {
      state[key as keyof ICompany] = action.payload[key as keyof ICompany]
    })
  },

  updateCompanyState: (
    state: ICompany,
    action: PayloadAction<AllOptionalProperties<ICompany>>,
  ) => {
    return {
      ...state,
      ...action.payload,
    }
  },
}
