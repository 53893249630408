import { createSlice } from '@reduxjs/toolkit'
import { type IAlert } from './IAlert'
import { alertReducers } from '../../reducers/alert/AlertReducers'

export const initialStateAlert: IAlert = {
  isVisible: false,
  title: '',
  description: '',
  actionLabel: '',
  actionType: null,
}

const alertSlice = createSlice({
  initialState: initialStateAlert,
  name: 'alert',
  reducers: alertReducers,
})

export default alertSlice.reducer
export const { showAlert, hideAlert } = alertSlice.actions
